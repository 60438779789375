var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('ProductCategory',{attrs:{"onQuantityChange":_vm.updateCart,"title":['MemXcel unit using transparent polycarbonate body'],"subtitle":['Model: MX-1-PC'],"items":[
        {no: 'MX-1-PC-kit', partName: 'Set', material: 'All the parts listed needed', specification: 'All the parts listed below, plus i) two porous Nickel membrane sheets, ii) two NaA zeolite/metal sheet zeolite membranes; iii) one additional inner and outer gaskets', partPrice: 699},
        {no: 'MX-1-PC-1', partName: 'Front (feed) plate', material: 'Polycarbonate', specification: '80 mm x 140 mm x10mm', partPrice: 250}
      ]}}),_c('ProductCategory',{attrs:{"title":['MemXcel unit using stainless-steel body'],"subtitle":['Model: MX-1-SS']}}),_c('ProductCategory',{attrs:{"title":['MoleculeWorks thin porous metal sheet membrane for micro- and ultra-filtration',
        'MoleculeWorks thin porous metal sheet as membrane support'],"subtitl":['Porous Ni alloy (95-99% Ni) sheet, thickness  40- 50µm']}}),_c('ProductCategory',{attrs:{"title":['MoleculeWorks thin A-type zeolite membrane sheet'],"subtitle":['Membrane thickness <10µm', 'Overall sheet thickness 40-60µm']}}),_c('ProductCategory',{attrs:{"title":['MoleculeWorks hydrophobic membrane sheet'],"subtitle":['Polytetrafluoroethylene (PTFE) coated on porous Ni sheet surface',
        'PTFE coating: thickness <10µm, not wettable by water',
        'yttria-stabilized zirconia (YSZ) coating: thickness < 10µm, wettable by water']}}),_c('ProductCategory',{attrs:{"title":['MoleculeWorks thin porous ceramic/metal sheet separator'],"subtitle":['yittria-stabilized zirconia (YSZ) particles on porous Ni sheet:',
        'Ceramic coating thickness = 10-15µm',
        'Overall sheet thickness = 50-60µm']}}),_c('ProductCategory',{attrs:{"title":['Part list for Membrane Encapsulated Planar Adsorbent (MEPA) plate']}}),_c('div',{attrs:{"id":"cart"}},[_vm._v(" Your current cart: "+_vm._s(_vm.cart)+" ")]),_c('div',[_c('button',{attrs:{"id":"checkout","disabled":_vm.isCartEmpty},on:{"click":_vm.startCheckout}},[_vm._v("Click here to proceed to checkout.")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }